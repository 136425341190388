import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{
      width:"100%",
      height:"0px",
      position:"relative",
      minHeight: 'calc(100vh - 9rem)',
      display: 'grid',
      placeContent: 'center'
    }}>
        <h1>Page NOT FOUND</h1>

    </div>
  </Layout>
)

export default NotFoundPage
